.form-control {
  input,
  textarea {
    @apply
    text-body-1-regular
    border-0
    placeholder-transparent
    overflow-ellipsis
    rounded
    bg-light-1
    w-full
    resize-none
    py-3
    px-4
    appearance-none; // чтоб box-shadow работал в сафари

    &:focus {
      @apply outline-none bg-light-1;

      box-shadow: inset 0 0 0 2px rgba(var(--orange-300), 1);
    }

    &.ng-touched {
      &.ng-invalid {
        box-shadow: inset 0 0 0 2px rgba(var(--error-600), 1);

        & ~ label.label {
          @apply text-error
        }

        & ~ figure.error {
          @apply visible;
        }

        & ~ app-error-tooltip {
          @apply block;
        }
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition-property: background-color;
      transition-delay: 1000000000s;
    }

    &:disabled {
      @apply opacity-50 select-none;
    }
  }

  > figure {
    @apply
    w-6 h-6
    box-content
    top-[28px]
    -translate-y-1/2;

    &.error {
      @apply invisible text-error;
    }

    &.password, &.error {
      @apply cursor-pointer select-none;
    }

    i {
      @apply top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
    }

    &.i-left {
      @apply left-4;

      & ~ input {
        @apply pl-10;
      }
    }

    &.i-right {
      @apply right-4;

      & ~ input {
        @apply pr-10;
      }
    }
  }

  label {
    @apply absolute pointer-events-none transition-all;

    &.label {
      @apply block left-4 top-2 text-on-light-2 text-caption-2-regular;
       max-width: calc(100% - theme('spacing.8'));
    }
  }

  app-error-tooltip {
    @apply hidden;
  }

  &.form-control-md {
    input,
    textarea {
      @apply py-3 px-4;
    }
  }

  &.form-control-lg {
    input,
    textarea {
      @apply pt-6 pb-2 px-4;
    }
  }

  &.form-control-light {
    input:not(:focus):not(.ng-touched.ng-invalid),
    textarea:not(:focus):not(.ng-touched.ng-invalid) {
      box-shadow: inset 0 0 0 2px rgba(var(--black), 0.25);
    }
  }

  &.form-control-accent {
    input:not(:focus):not(.ng-touched.ng-invalid),
    textarea:not(:focus):not(.ng-touched.ng-invalid) {
      @apply bg-primary-50;
    }

    input:not(:focus):not(.ng-touched.ng-invalid):disabled,
    textarea:not(:focus):not(.ng-touched.ng-invalid):disabled {
      @apply bg-neutral-25 opacity-100 text-neutral-200;

      & ~ label.label {
        @apply text-neutral-200;
      }
    }

    input:not(.ng-touched.ng-invalid):not(:disabled):focus,
    input:not(.ng-touched.ng-invalid):not(:disabled):hover,
    textarea:not(.ng-touched.ng-invalid):not(:disabled):focus,
    textarea:not(.ng-touched.ng-invalid):not(:disabled):hover,{
      @apply outline-none text-neutral-3000 bg-neutral-25;

      box-shadow: inset 0 0 0 2px rgba(var(--neutral-400), 1);

      & ~ label.label {
        @apply text-neutral-400;
      }
    }
  }

  &.coupon-input {
    input {
      @apply
      py-4
      pl-4
      pr-28
      bg-transparent
      placeholder-neutral-800
      placeholder-opacity-25;

      box-shadow: inset 0 0 0 2px rgba(var(--black));

      &:focus {
        @apply bg-transparent;

        box-shadow: inset 0 0 0 2px rgba(var(--neutral-400));
      }
    }
  }

  .coupon-button {
    @apply
    absolute
    block
    top-1/2
    -translate-y-1/2
    right-4
    text-control-2-semibold
    text-on-light-1;
  }
}

.form-control-textarea {
  @apply
  relative
  flex
  p-2 pl-3;

  .form-control-wrap {
    @apply
    absolute
    top-0 left-0
    w-full h-full
    rounded
    bg-light-1;

    box-shadow: inset 0 0 0 2px rgba(var(--black), 0.25);
  }

  .form-control-label {
    @apply
    inline-block
    absolute
    top-1.5 left-3
    m-0 p-0
    text-on-light-2
    text-caption-2-regular
    truncate
    pointer-events-none
    select-none
    transition-all
    z-[1];

    max-width: calc(100% - theme("spacing.6"));
  }

  textarea {
    @apply
    z-[1]
    w-full
    resize-none
    pr-1 pt-4
    outline-none
    bg-transparent
    placeholder-transparent
    overflow-y-auto;

    &::-webkit-scrollbar {
      @apply w-1 rounded-lg bg-light-1 cursor-pointer;
    }

    &::-webkit-scrollbar-thumb {
      @apply rounded-lg bg-dark-3 cursor-pointer;
    }

    @extend .text-body-1-regular;

    transition: height 0.6s;

    &:focus ~ .form-control-wrap {
      @apply bg-light-1;

      box-shadow: inset 0 0 0 2px rgba(var(--orange-300), 1);
    }

    &:not(:focus):not(:placeholder-shown) ~ .form-control-wrap {
      @apply bg-light-1;

      box-shadow: inset 0 0 0 2px rgba(var(--black), 0.25);
    }

    &:focus ~ .form-control-label,
    &:not(:focus):not(:placeholder-shown) ~ .form-control-label {
      @apply bg-light-1 w-full;
    }

    &.ng-touched {
      &.ng-invalid ~ .form-control-wrap {
        height: calc(100% - 14px);

        box-shadow: inset 0 0 0 1px rgba(var(--red-500), 1);
      }

      &.ng-invalid ~ figure.error {
        @apply visible;
      }

      &.ng-invalid ~ app-error-tooltip {
        @apply block;
      }
    }
  }

  app-error-tooltip {
    @apply hidden translate-y-[10px] -translate-x-3;
  }

  > figure {
    @apply
    w-6 h-6
    box-content
    top-1/2
    -translate-y-1/2;

    &.error {
      @apply invisible text-error;
    }

    &.error {
      @apply cursor-pointer select-none;
    }

    i {
      @apply top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
    }

    &.i-left {
      @apply left-4;

      & ~ input {
        @apply pl-10;
      }
    }

    &.i-right {
      @apply right-4;

      & ~ input {
        @apply pr-10;
      }
    }
  }

  &.form-control-accent {
    @apply flex-col;

    .form-control-wrap {
      @apply bg-primary-50;

      box-shadow: inset 0 0 0 2px rgba(0,0,0,0.0);
    }

    .form-control-label {
      @apply text-neutral-200;
    }

    textarea {
      @apply text-neutral-3000;

      &:focus ~ .form-control-wrap,
      &:hover ~ .form-control-wrap {
        @apply bg-neutral-50;

        box-shadow: inset 0 0 0 2px rgba(var(--neutral-400), 1);
      }

      &:not(:focus):not(:placeholder-shown) ~ .form-control-wrap {
        @apply bg-neutral-50;
      }

      &:focus ~ .form-control-label,
      &:not(:focus):not(:placeholder-shown) ~ .form-control-label {
        @apply w-full bg-neutral-50;
      }

      &.ng-touched {
        &.ng-invalid ~ .form-control-wrap {
          box-shadow: inset 0 0 0 2px rgba(var(--error-600), 1);
        }
      }
    }
  }
}

form {
  &.ng-invalid {
    .form-control {
      &.error-full {
        input {
          &.ng-touched {
            box-shadow: inset 0 0 0 2px rgba(var(--error-600));

            & ~ label.label {
              @apply text-error
            }

            & ~ figure.error {
              @apply visible;
            }
          }
        }
      }
    }
  }
}
