.btn {
  @apply
  flex
  items-center
  whitespace-nowrap
  transition-all
  duration-100
  ease-linear;

  @extend .btn-md;

  &.btn-md {
    @apply rounded p-3;

    @extend .text-control-md-semibold;

    figure {
      @apply relative w-6 h-6;

      i {
        @apply absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
      }
    }

    span {
      @apply px-1 py-0.5;
    }
  }

  &.btn-lg {
    @apply rounded p-4;

    @extend .text-control-lg-semibold;

    span {
      @apply px-2 py-0;
    }
  }

  &.btn-primary {
    &.btn-inline {
      @apply bg-primary-3000 text-on-dark-1;

      figure {
        i {
          @apply text-on-dark-1;
        }
      }

      &:not(:disabled) {
        &:hover {
          @apply bg-secondary-800;
        }
      }

      &:disabled {
        @apply cursor-not-allowed bg-neutral-100;
      }
    }

    &.btn-transparent {
      @apply bg-transparent text-primary-3000 p-0;

      figure {
        i {
          @apply text-primary-3000;
        }
      }

      span {
        @apply py-0;
      }

      &:not(:disabled) {
        &:hover {
          @apply text-secondary-800;

          figure {
            i {
              @apply text-secondary-800;
            }
          }
        }
      }

      &:disabled {
        @apply cursor-not-allowed text-neutral-100;

        figure {
          i {
            @apply text-neutral-100;
          }
        }
      }
    }
  }

  &.btn-on-light-1 {
    &.btn-inline {
      @apply bg-neutral-1 text-neutral-3000;

      figure {
        i {
          @apply text-neutral-3000;
        }
      }

      &:not(:disabled) {
        &:hover {
          @apply bg-neutral-100;
        }
      }

      &:disabled {
        @apply cursor-not-allowed text-neutral-100;
      }
    }

    &.btn-transparent {
      @apply bg-transparent text-neutral-3000 p-0;

      figure {
        i {
          @apply text-neutral-3000;
        }
      }

      span {
        @apply py-0;
      }

      &:not(:disabled) {
        &:hover {
          @apply opacity-80;
        }
      }

      &:disabled {
        @apply cursor-not-allowed text-neutral-100;
      }
    }
  }

  &.btn-secondary {
    &.btn-inline {
      @apply bg-pink-500 text-neutral-25;

      figure {
        i {
          @apply text-neutral-25;
        }
      }

      &:not(:disabled) {
        &:hover {
          @apply bg-secondary-800;
        }
      }

      &:disabled {
        @apply cursor-not-allowed bg-neutral-100;
      }
    }

    &.btn-transparent {
      @apply bg-transparent text-pink-500 p-0;

      figure {
        i {
          @apply text-pink-500;
        }
      }

      span {
        @apply py-0;
      }

      &:not(:disabled) {
        &:hover {
          @apply text-secondary-800;

          figure {
            i {
              @apply text-secondary-800;
            }
          }
        }
      }

      &:disabled {
        @apply cursor-not-allowed text-neutral-100;

        figure {
          i {
            @apply text-neutral-100;
          }
        }
      }
    }
  }

  &.btn-on-dark-1 {
    &.btn-inline {
      @apply bg-light-1 text-primary;

      figure {
        i {
          @apply text-primary;
        }
      }

      &:not(:disabled) {
        &:hover {
          @apply opacity-80;
        }
      }

      &:disabled {
        @apply cursor-not-allowed opacity-[15%];
      }
    }

    &.btn-transparent {
      @apply bg-transparent text-on-dark-1 p-0;

      figure {
        i {
          @apply text-on-dark-1;
        }
      }

      span {
        @apply py-0;
      }

      &:not(:disabled) {
        &:hover {
          @apply opacity-80;
        }
      }

      &:disabled {
        @apply cursor-not-allowed opacity-[15%];
      }
    }
  }

  &.btn-error {
    &.btn-inline {
      @apply bg-error-1 text-on-light-1;

      figure {
        i {
          @apply text-on-light-1;
        }
      }

      &:not(:disabled) {
        &:hover {
          @apply opacity-80;
        }
      }

      &:disabled {
        @apply cursor-not-allowed opacity-[15%];
      }
    }

    &.btn-transparent {
      @apply bg-transparent text-error p-0;

      figure {
        i {
          @apply text-error;
        }
      }

      span {
        @apply py-0;
      }

      &:not(:disabled) {
        &:hover {
          @apply opacity-80;
        }
      }

      &:disabled {
        @apply cursor-not-allowed opacity-[15%];
      }
    }
  }

  &.btn-success {
    &.btn-transparent {
      @apply bg-transparent text-success p-0;

      figure {
        i {
          @apply text-success;
        }
      }

      span {
        @apply py-0;
      }

      &:not(:disabled) {
        &:hover {
          @apply opacity-80;
        }
      }

      &:disabled {
        @apply cursor-not-allowed opacity-[15%];
      }
    }
  }
}
