@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants peer-focus, peer-placeholder-shown {

    .label-focus {
      @apply
      text-caption-2-regular
      text-on-light-2
      translate-y-0;
    }

    .label-placeholder-shown {
      @apply
      text-body-1-regular
      text-on-light-2
      translate-y-2;
    }

    .button-focus {
      @apply text-neutral-3000;
    }
  }
}
