@charset "utf-8";

/* https://tailwindcss.com/ */

@import "styles/vendors/_tailwind.css";
@import "styles/vendors/payment";

/* COMPONENTS */
@import "styles/components/badge";
@import "styles/components/buttons";
@import "styles/components/inputs";
@import "styles/components/router";

/* BASE */
@import "styles/base/shadow";
@import "styles/base/colors";
@import "styles/base/typography";
@import "styles/base/view-height";
