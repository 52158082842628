.discount-badge {
  @apply
  relative
  flex
  items-center
  p-0.5 pr-1
  ml-[11px]
  bg-secondary-1
  rounded-r-sm;

  &::after {
    @apply
    absolute
    top-0
    -left-[11px]
    w-[11px]
    h-5;

    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='20' viewBox='0 0 11 20' fill='none'%3E%3Cg clip-path='url(%23clip0_6155_12018)'%3E%3Cpath d='M0.327929 10.7595C-0.114295 10.3622 -0.114294 9.66908 0.327931 9.27176L10.3473 0.269756C10.991 -0.308596 12.0156 0.148252 12.0156 1.01362L12.0156 19.0176C12.0156 19.883 10.991 20.3398 10.3473 19.7615L0.327929 10.7595Z' fill='%23FFA670'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_6155_12018'%3E%3Crect width='11' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }

  span {
    @apply text-caption-2-semibold text-on-light-1;
  }
}
