:root {
  --white: 255, 255, 255;
  --black: 56, 56, 56; // #383838

  --neutral-3000: 33, 29, 31; // #211D1F
  --neutral-900: 37, 23, 3316; // #25171F
  //--neutral-800: 70, 55, 63; // #46373F
  --neutral-800: 109, 94, 101; // #6D5E65
  --neutral-700: 101, 85, 93; // #65555D
  //--neutral-600: 121, 104, 113; // #796871
  --neutral-600: 149, 131, 140; // #95838C;
  --neutral-500: 163, 145, 154; // #A3919A
  //--neutral-400: 193, 174, 184; // #C1AEB8
  --neutral-400: 176, 155, 166; // #B09BA6
  --neutral-300: 243, 242, 245; // #E5D2DC
  //--neutral-200: 238, 224, 231; // #EEE0E7
  --neutral-200: 212, 196, 204; // #D4C4CC
  //--neutral-100: 243, 232, 237; // #F3E8ED
  --neutral-100: 232, 217, 224; // #E8D9E0
  --neutral-50: 243, 238, 240; // #F3EEF0
  --neutral-25: 250, 247, 249; // #FAF7F9

  --pink-900: 91, 29, 60; // #5B1D3C
  --pink-800: 120, 43, 82; // #782B52
  --pink-700: 149, 59, 104; // #953B68
  --pink-600: 178, 77, 128; // #B24D80
  --pink-500: 207, 98, 153; // #CF6299
  --pink-400: 236, 120, 179; // #EC78B3
  --pink-300: 255, 139, 198; // #FF8BC6
  --pink-200: 255, 174, 215; // #FFAED7
  --pink-100: 255, 209, 232; // #FFD1E8
  --pink-50: 255, 243, 249; // #FFF3F9

  --pink-alt-900: 91, 29, 60; // #5B1D3C
  --pink-alt-800: 120, 43, 82; // #782B52
  --pink-alt-700: 149, 59, 104; // #953B68
  --pink-alt-600: 178, 77, 128; // #B24D80
  --pink-alt-500: 207, 98, 153; // #CF6299
  --pink-alt-400: 236, 120, 179; // #EC78B3
  --pink-alt-300: 255, 139, 198; // #FF8BC6
  --pink-alt-200: 255, 174, 215; // #FFAED7
  --pink-alt-100: 255, 209, 232; // #FFD1E8
  --pink-alt-50: 255, 243, 249; // #FFF3F9

  --red-900: 85, 7, 0; // #550700
  --red-800: 119, 10, 0; // #770A00
  --red-700: 153, 18, 6; // #991206
  --red-600: 187, 32, 17; // #BB2011
  --red-500: 221, 48, 32; // #DD3020
  --red-400: 255, 68, 51; // #FF4433
  --red-300: 255, 110, 97; // #FF6E61
  --red-200: 255, 152, 143; // #FF988F
  --red-100: 255, 194, 189; // #FFC2BD
  --red-50: 255, 236, 234; // #FFECEA

  --orange-900: 96, 0, 0; // #600000
  --orange-800: 137, 0, 0; // #890000
  --orange-700: 177, 26, 0; // #B11A00
  --orange-600: 203, 53, 7; // #CB3507
  --orange-500: 255, 99, 51; // #FF6333
  --orange-400: 255, 130, 78; // #FF824E
  --orange-300: 255, 166, 112; // #FFA670
  --orange-200: 255, 180, 125; // #FFB47D
  --orange-100: 255, 204, 189; // #FFCCBD
  --orange-50: 255, 239, 234; // #FFEFEA

  --burgundy-900: 72, 10, 43; // #480A2B
  --burgundy-800: 111, 18, 70; // #6F1246
  --burgundy-700: 144, 20, 86; // #901456
  --burgundy-600: 180, 24, 102; // #B41866
  --burgundy-500: 213, 63, 138; // #D53F8A
  --burgundy-400: 232, 115, 173; // #E873AD
  --burgundy-300: 242, 156, 199; // #F29CC7
  --burgundy-200: 248, 191, 219; // #F8BFDB
  --burgundy-100: 253, 226, 240; // #FDE2F0
  --burgundy-50: 255, 243, 249; // #FFF3F9

  --burgundy-alt-900: 79, 34, 56; // #4F2238
  --burgundy-alt-800: 101, 41, 71; // #652947
  --burgundy-alt-700: 131, 50, 90; // #83325A
  --burgundy-alt-600: 159, 65, 112; // #9F4170
  --burgundy-alt-500: 181, 84, 133; // #B55485
  --burgundy-alt-400: 205, 121, 163; // #CD79A3
  --burgundy-alt-300: 226, 162, 194; // #E2A2C2
  --burgundy-alt-200: 241, 198, 219; // #F1C6DB
  --burgundy-alt-100: 249, 231, 240; // #F9E7F0
  --burgundy-alt-50: 253, 245, 249; // #FDF5F9

  --rose-900: 85, 13, 26; // #550D1A
  --rose-800: 127, 19, 40; // #7F1328
  --rose-700: 170, 26, 53; // #AA1A35
  --rose-600: 212, 32, 66; // #D42042
  --rose-500: 235, 70, 100; // #EB4664
  --rose-400: 240, 115, 138; // #F0738A
  --rose-300: 245, 166, 181; // #F5A6B5
  --rose-200: 250, 210, 217; // #FAD2D9
  --rose-100: 252, 232, 235; // #FCE8EB
  --rose-50: 253, 243, 245; // #FDF3F5

  --salmon-900: 102, 35, 24; // #662318
  --salmon-800: 142, 53, 37; // #8E3525
  --salmon-700: 191, 78, 57; // #BF4E39
  --salmon-600: 229, 102, 79; // #E5664F
  --salmon-500: 239, 123, 110; // #EF7B6E
  --salmon-400: 243, 152, 146; // #F39892
  --salmon-300: 245, 177, 175; // #F5B1AF
  --salmon-200: 245, 217, 215; // #F5D9D7
  --salmon-100: 250, 238, 238; // #FAEEEE
  --salmon-50: 254, 248, 248; // #FEF8F8

  --brown-900: 79, 74, 65; // #4F4A41
  --brown-800: 102, 96, 85; // #666055
  --brown-700: 125, 118, 106; // #7D766A
  --brown-600: 147, 140, 128; // #938C80
  --brown-500: 170, 163, 151; // #AAA397
  --brown-400: 193, 186, 174; // #C1BAAE
  --brown-300: 215, 209, 199; // #D7D1C7
  --brown-200: 238, 233, 224; // #EEE9E0
  --brown-100: 243, 241, 238; // #F3F1EE
  --brown-50: 255, 253, 250; // #FFFDFA

  --olive-900: 32, 47, 0; // #202F00
  --olive-800: 55, 82, 0; // #375200
  --olive-700: 87, 114, 0; // #577200
  --olive-600: 109, 135, 0; // #6D8700
  --olive-500: 153, 177, 47; // #99B12F
  --olive-400: 184, 207, 78; // #B8CF4E
  --olive-300: 204, 220, 127; // #CCDC7F
  --olive-200: 223, 234, 174; // #DFEAAE
  --olive-100: 233, 242, 192; // #E9F2C0
  --olive-50: 248, 251, 234; // #F8FBEA

  --teal-900: 0, 47, 60; // #002F3C
  --teal-800: 0, 82, 95; // #00525F
  --teal-700: 0, 114, 127; // #00727F
  --teal-600: 0, 134, 148; // #008694
  --teal-500: 69, 177, 191; // #45B1BF
  --teal-400: 104, 208, 222; // #68D0DE
  --teal-300: 142, 244, 255; // #8EF4FF
  --teal-200: 157, 255, 255; // #9DFFFF
  --teal-100: 190, 255, 255; // #BEFFFF
  --teal-50: 216, 253, 253; // #D8FDFD

  --purple-900: 77, 5, 90; // #4D055A
  --purple-800: 116, 48, 127; // #74307F
  --purple-700: 151, 81, 161; // #9751A1
  --purple-600: 173, 101, 184; // #AD65B8
  --purple-500: 218, 143, 228; // #DA8FE4
  --purple-400: 250, 173, 255; // #FAADFF
  --purple-300: 255, 209, 255; // #FFD1FF
  --purple-200: 255, 224, 255; // #FFE0FF
  --purple-100: 255, 235, 255; // #FFEBFF
  --purple-50: 255, 247, 255; // #FFF7FF

  --primary-50: 244, 236, 241; // #F4ECF1
  --primary-3000: 33, 29, 31; // #211D1F

  --secondary-800: 156, 68, 113; // #9C4471
  --secondary-900: 117, 53, 86; // #753556

  --success-50: 241, 243, 226; // #F1F3E2

  --green-100: 226, 232, 196; // #E2E8C4

  --error-50: 252, 234, 231; // #FCEAE7
  --error-100: 249, 212, 204; // #F9D4CC
  --error-600: 235, 99, 71; // #EB6347

  --success-600: 150, 171, 43; // #96AB2B
}

@layer utilities {
  /* TEXT */
  .text-on-light-1 {
    @apply text-black;
  }
  .text-on-light-2 {
    @apply text-black text-opacity-50;
  }
  .text-on-light-3 {
    @apply text-black text-opacity-25;
  }
  .text-on-dark-1 {
    @apply text-white;
  }
  .text-on-dark-2 {
    @apply text-white text-opacity-50;
  }
  .text-on-dark-3 {
    @apply text-white text-opacity-25;
  }
  .text-primary {
    @apply text-pink-600;
  }
  .text-secondary-1 {
    @apply text-orange-500;
  }
  .text-secondary-2 {
    @apply text-neutral-300;
  }
  .text-error {
    @apply text-red-500;
  }
  .text-success {
    @apply text-olive-500;
  }

  /* BACKGROUND  */
  .bg-light-1 {
    @apply bg-white;
  }
  .bg-dark-1 {
    @apply bg-black;
  }
  .bg-dark-2 {
    border-color: rgba(var(--neutral-200), 0.02); // rgba(238, 224, 231, 0.02)
  }
  .bg-dark-3 {
    @apply bg-black bg-opacity-25;
  }
  .bg-neutral-1 {
    @apply bg-neutral-50;
  }
  .bg-primary-1 {
    @apply bg-pink-600;
  }
  .bg-primary-2 {
    @apply bg-pink-600 bg-opacity-25;
  }
  .bg-primary-3 {
    @apply bg-pink-alt-700 bg-opacity-25;
  }
  .bg-primary-4 {
    @apply bg-pink-700;
  }
  .bg-primary-5 {
    @apply bg-pink-900;
  }
  .bg-secondary-1 {
    @apply bg-orange-300;
  }
  .bg-secondary-2 {
    @apply bg-neutral-200;
  }
  .bg-secondary-3 {
    @apply bg-neutral-50;
  }
  .bg-error-1 {
    @apply bg-red-500;
  }
  .bg-error-2 {
    @apply bg-red-100;
  }
  .bg-success {
    @apply bg-olive-500;
  }
  .bg-success-2 {
    @apply bg-olive-200;
  }
  .bg-gradient-light-1 {
    background: linear-gradient(180deg, rgba(var(--white), 0.15) 0%, rgb(var(--white)) 88.02%, rgb(var(--white)) 100%);
  }
  .bg-gradient-secondary-2 {
    background: linear-gradient(180deg, rgba(var(--neutral-200), 0.40) 0%, rgba(var(--white), 0) 100%);
  }
  .bg-gradient-primary-2 {
    background: linear-gradient(180deg, rgba(var(--pink-900), 0.5) 0%, rgba(var(--pink-900), 0.15) 100%);
  }
  .bg-gradient-primary-3 {
    background: linear-gradient(180deg, rgba(var(--pink-600), 0.6) 0%, rgba(var(--pink-600), 0.2) 100%);
  }
  .bg-gradient-primary-4 {
    background: linear-gradient(180deg, rgba(var(--pink-900), 0) 0%, rgb(var(--pink-900)) 88.02%, rgb(var(--pink-900)) 100%);
  }
  .bg-placeholder-1 {
    @apply bg-neutral-50;
  }
  .bg-placeholder-2 {
    @apply bg-neutral-200;
  }

  /* BORDER */
  .border-light-1 {
    @apply border-white;
  }
  .border-light-2 {
    @apply border-white border-opacity-10;
  }
  .border-light-3 {
    @apply border-neutral-800 border-opacity-25;
  }
  .border-dark-1 {
    @apply border-black;
  }
  .border-dark-2 {
    @apply border-black border-opacity-10;
  }
  .border-dark-3 {
    @apply border-black border-opacity-25;
  }
  .border-primary {
    @apply border-pink-600;
  }
  .border-secondary-1 {
    @apply border-orange-300;
  }
  .border-secondary-2 {
    @apply border-neutral-200;
  }
  .border-error {
    @apply border-red-500;
  }
  .border-success {
    @apply border-olive-500;
  }
}
