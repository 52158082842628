
@layer components {
  .snackbar-shadow {
    box-shadow: 0px 3px 8px rgba(var(--neutral-800), 0.1), 0px 2px 1px rgba(var(--neutral-800), 0.05);
  }
  .bottom-bar-shadow {
    box-shadow: 0px -3px 16px rgba(var(--neutral-800), 0.1), 0px 2px 1px rgba(var(--neutral-800), 0.05);
  }
  .sm-shadow {
    box-shadow: 0px 0px 3px rgba(var(--neutral-800), 0.1);
  }
  .shadow-neutral-sm {
    box-shadow: 0px 0px 4px 0px var(--neutral-opacity-30005, rgba(33, 29, 31, 0.05)), 0px 2px 4px 0px var(--neutral-opacity-300010, rgba(33, 29, 31, 0.10)), 0px 1px 2px -1px var(--neutral-opacity-300010, rgba(33, 29, 31, 0.10));
  }
}
