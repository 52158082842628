@each $weight, $name in (
  400: 'Regular',
  500: 'Midium',
  600: 'Semibold',
  700: 'Bold',
) {
  @font-face {
    font-family: 'Lora';
    src:
      local('Lora #{$name}'),
      local('Lora-#{$name}'),
      url('../../assets/fonts/lora/Lora-#{$weight}.woff') format('woff'),
      url('../../assets/fonts/lora/Lora-#{$weight}.woff2') format('woff2');
    font-weight: $weight;
    font-style: normal;
  }
}

@each $weight, $name in (
  400: 'Regular',
  500: 'Medium',
  600: 'Semibold',
  700: 'Bold',
) {
  @font-face {
    font-family: 'Montserrat';
    src:
      local('Montserrat #{$name}'),
      local('Montserrat-#{$name}'),
      url('../../assets/fonts/montserrat/Montserrat-#{$weight}.woff') format('woff'),
      url('../../assets/fonts/montserrat/Montserrat-#{$weight}.woff2') format('woff2');
    font-weight: $weight;
    font-style: normal;
  }
}

@layer components {
  .text-display-1-regular {
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
  }
  .text-display-1-semibold {
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
  }
  .text-display-1-bold {
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  }

  .text-landing-title-0-semibold {
    font-family: 'Lora', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 64px;
  }

  .text-landing-title-1-semibold {
    font-family: 'Lora', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  .text-title-1-regular {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  .text-title-1-semibold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .text-title-1-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  .text-title-2-regular {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }
  .text-title-2-semibold {
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  .text-title-2-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }

  .text-title-desktop-xl-semibold-accent {
    font-family: 'Lora', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
  .text-title-desktop-lg-semibold-accent {
    font-family: 'Lora', sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
  .text-title-desktop-xs-semibold-accent {
    font-family: 'Lora', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .text-title-desktop-sm-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .text-title-desktop-xs-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .text-title-tablet-sm-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .text-title-mobile-lg-semibold-accent {
    font-family: 'Lora', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .text-title-mobile-sm-semibold-accent {
    font-family: 'Lora', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .text-title-mobile-md-semibold-accent {
    font-family: 'Lora', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .text-title-mobile-xl-semibold-accent {
    font-family: 'Lora', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }

  .text-title-mobile-sm-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .text-subtitle-1-regular {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }
  .text-subtitle-1-semibold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }
  .text-subtitle-1-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }

  .text-subtitle-desktop-xs-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .text-subtitle-desktop-md-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .text-subtitle-mobile-sm-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .text-subtitle-mobile-md-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .text-subtitle-mobile-lg-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .text-body-1-regular {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  .text-body-1-semibold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .text-body-1-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  .text-body-2-regular {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }
  .text-body-2-semibold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }
  .text-body-2-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }

  .text-body-desktop-xs-regular {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .text-body-desktop-xl-regular {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
  .text-body-desktop-md-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
  .text-body-desktop-sm-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }


  .text-body-mobile-sm-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  .text-body-mobile-md-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .text-body-mobile-xl-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .text-body-mobile-md-regular {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .text-body-mobile-lg-regular {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .text-body-mobile-xl-regular {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .text-control-1-regular {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  .text-control-1-semibold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .text-control-1-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  .text-control-2-regular {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }
  .text-control-2-semibold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }
  .text-control-2-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }

  .text-control-3-regular {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    letter-spacing: 0.12px;
  }
  .text-control-3-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    letter-spacing: 0.12px;
  }
  .text-control-3-bold {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;

    letter-spacing: 0.12px;
  }

  .text-control-sm-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }
  .text-control-md-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
  .text-control-lg-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .text-caption-1-regular {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }
  .text-caption-1-semibold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }
  .text-caption-1-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.07px;
  }

  .text-caption-2-regular {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: 0.12px;
  }
  .text-caption-2-semibold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: 0.12px;
  }
  .text-caption-2-bold {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: 0.12px;
  }

  .text-caption-sm-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }
  .text-caption-md-semibold {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.07px;
  }

  .text-caption-xs-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }
  .text-caption-sm-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  }
  .text-caption-md-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.07px;
  }
  .text-caption-lg-medium {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .break-words-anywhere {
    overflow-wrap: anywhere;
  }
}
